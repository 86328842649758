import React from 'react'
import { Container } from 'reactstrap'

import Layout from 'layouts/static'

import H2 from 'components/H2'
import H3 from 'components/H3'

const NotFoundPage = () => (
  <Layout>
    <Container className="text-center pt-5">
      <H2>Strona nie została znaleziona.</H2>
      <br />
      <H3>&lt; Błąd 404 &gt;</H3>
    </Container>
  </Layout>
)

export default NotFoundPage
